import React from "react";

import './projectCard.css';

function ProjectCard(props) {
  return (
    <div className="masonry__item masonry-block__item">
      <a href={props.link} target="_blank" rel="noreferrer" className="masonry-item"
        style={{ marginLeft: "0px", paddingLeft: "0px" }}>
        <div>{props.children}</div>
        <span className="masonry-item__title">{props.name}</span>
        <span className="masonry-item__category">
          {props.category.map((c) =>
            String(c) !== "all"
              ? String(c).charAt(0).toUpperCase() + String(c).slice(1) + " "
              : ""
          )}
        </span>
      </a>
    </div>
  );
}

export default ProjectCard;
