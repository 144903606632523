import React from "react";
import ReactPlayer from "react-player";
import Fade from "react-reveal";

import { useMediaQuery } from "react-responsive";

import "../styles/startsection.css";
import { StaticImage } from "gatsby-plugin-image";

function StartSection() {
  const isMobile = useMediaQuery({ query: '(max-width: 764px)' });
  const fontSizeWelcome = isMobile ? "25px" : "2.25em";
  const fontSizeQualityText = isMobile ? "20px" : "1.5rem";

  return (
    <section
      id="home"
      className="container"
      style={{ height: "100%", paddingBottom: "100px" }}
    >
      <div className="welcomeText" style={{ fontSize: fontSizeWelcome, }}>
        Herzlich Willkommen bei<br /> Hoxhaj Bauunternehmen GmbH
      </div>

      {isMobile && <div style={{ display: "flex", justifyContent: "center", margin: 30, marginTop: 50 }}>
        <StaticImage src="../img/own/company-logo.png" width={200} />
      </div>}

      <div className="row start">
        <header className="about__header">
          <Fade bottom>
            <h1 className="subheader" style={{
              color: "white",
              textAlign: "center",
              fontSize: fontSizeQualityText,
            }}>
              Qualität ist das, was wir verfolgen.
            </h1>
          </Fade>
        </header>

        <div className="row statsdisplay" style={{ flexDirection: isMobile ? "column" : "row", }}>
          <Fade bottom>
            <div className="row statistics__item" style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row"
            }}>
              <div className="statistics__item-value">100%</div>
              <div className="statistics__item-text">
                zufriedene
                <br />
                kunden
              </div>
            </div>
            <div className="row statistics__item" style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row"
            }}>
              <div className="statistics__item-value">20+</div>
              <div className="statistics__item-text">
                jahre
                <br />
                erfahrung
              </div>
            </div>
            <div className="row statistics__item" style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row"
            }}>
              <div className="statistics__item-value">100+</div>
              <div className="statistics__item-text">
                projekte
                <br />
              </div>
            </div>
          </Fade>
        </div>
        <ReactPlayer
          style={{ marginTop: isMobile ? 10 : 50, marginLeft: 15, marginRight: 15 }}
          autoPlay={true}
          width={1000}
          height={500}
          controls={false}
          playing={true}
          volume={0}
          loop={true}
          url="https://player.vimeo.com/video/647542131?h=c9ef2e1300"
        />
      </div>
    </section>
  );
}

export default StartSection;
