import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Colors from "../Colors";

import "./projects.css";

import ProjectCard from "./ProjectCard";

const portfolio = [
  {
    name: "Germersheim - Tierklinik",
    category: ["all", "sonstiges"],
    img: "../../img/projects/img1.jpg",
    link: "https://www.youtube.com/watch?v=RfS6pbzplhs",
  },
  {
    name: "Karlsruhe-Daxlanden - Wohnanlage",
    category: ["all", "Reihenhäuser"],
    img: "../../img/projects/img2.jpg",
    link: "https://www.youtube.com/watch?v=OLeZPkSw3-0",
  },
  {
    name: "August-Klingler-Areal - Heidelberg - Wohnanlage & Stadtterassen",
    category: ["all", "sonstiges"],
    img: "../../img/projects/img3.jpg",
    link: "https://www.youtube.com/watch?v=fMpnROsy_68",
  },
  {
    name: "Hornacker - Reihenhäuser",
    category: ["all", "Reihenhäuser"],
    img: "../../img/projects/img4.jpg",
    link: "https://www.youtube.com/watch?v=KjpAmSpoHtA&t=1s",
  },
  {
    name: "Bad Schönborn - 47 Wohneinheiten mit Tiefgarage",
    category: ["all", "mehrfamilienhaus"],
    img: "../../img/projects/img5.jpg",
    link: "https://www.youtube.com/watch?v=EntCsMbIWgQ",
  },
  {
    name: "Sinzheim - Mehrfamilienhaus",
    category: ["all", "mehrfamilienhaus"],
    img: "../../img/projects/img6.jpg",
    link: "https://www.youtube.com/watch?v=x63r3mqduS0",
  },
  {
    name: "Karlsruhe-Durlach - 3 Mehrfamilienhäuser mit Tiefgarage",
    category: ["all", "wohngebäude", "tiefgarage"],
    img: "../../img/projects/img7.jpg",
    link: "https://www.youtube.com/watch?v=6quTNjgZTd0",
  },
  {
    name: "Weil der Stadt - Altenpflegezentrum & Seniorenheim",
    category: ["all", "sonstiges"],
    img: "../../img/projects/img8.jpg",
    link: "https://www.youtube.com/watch?v=Cf8273z2dpk",
  },
];

function Projects() {
  // categories: all, Reihenhäuser, mehrfamilienhaus, sonstiges
  const [filter, setFilter] = useState("all");

  const width = 300;
  const height = 200;

  const projectImages = [
    <StaticImage
      src={`../../img/projects/img1.jpg`}
      loading="lazy"
      alt=""
      width={width}
      height={height}
      className="masonry-item__image"
    />,
    <StaticImage
      src={`../../img/projects/img2.jpg`}
      loading="lazy"
      alt=""
      width={width}
      height={height}
      className="masonry-item__image"
    />,
    <StaticImage
      src={`../../img/projects/img3.jpg`}
      loading="lazy"
      alt=""
      width={width}
      height={height}
      className="masonry-item__image"
    />,
    <StaticImage
      src={`../../img/projects/img4.jpg`}
      loading="lazy"
      alt=""
      width={width}
      height={height}
      className="masonry-item__image"
    />,
    <StaticImage
      src={`../../img/projects/img5.jpg`}
      loading="lazy"
      alt=""
      width={width}
      height={height}
      className="masonry-item__image"
    />,
    <StaticImage
      src={`../../img/projects/img6.jpg`}
      loading="lazy"
      alt=""
      width={width}
      height={height}
      className="masonry-item__image"
    />,
    <StaticImage
      src={`../../img/projects/img7.jpg`}
      loading="lazy"
      alt=""
      width={width}
      height={height}
      className="masonry-item__image"
    />,
    <StaticImage
      src={`../../img/projects/img8.jpg`}
      loading="lazy"
      alt=""
      width={width}
      height={height}
      className="masonry-item__image"
    />,
    <StaticImage
      src={`../../img/projects/img9.jpg`}
      loading="lazy"
      alt=""
      width={width}
      height={height}
      className="masonry-item__image"
    />,
    <StaticImage
      src={`../../img/own/company-logo.png`}
      loading="lazy"
      alt=""
      width={150}
    />,
  ];

  useEffect(() => {
    var btns = document.querySelectorAll(".filterbutton");
    btns.forEach((x) => (x.style.backgroundColor = "transparent"));

    var btn = document.getElementById(filter.toString());

    if (typeof btn != "undefined" && btn != null) {
      btn.style.backgroundColor = Colors.BUTTON_COLOR;
    }
  }, [filter]);

  const style = { backgroundColor: "black" };

  return (
    <section
      style={{ backgroundColor: "black", paddingTop: "100px" }}
      className="webpage__latest-projects latest-projects"
      id="projects"
    >
      <div className="container">
        <header className="latest-projects__header">
          <h2 className="latest-projects__heading heading">Unsere Projekte</h2>
          {/* <a className="latest-projects__more more" href="works-grid-dark.html">
            alle projekte ansehen
          </a> */}
        </header>
      </div>
      <div style={style} className="latest-projects__inner container">
        <div
          style={style}
          className="latest-projects__slider slider slider--with-thumbs swiper-container __js_slider-simple"
        >
          <div style={style} className="masonry masonry-block">
            <div style={style} className="container">
              <div className="masonry-block__head">
                <div
                  className="masonry__primary-filters"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignContent: "center",
                    justfiyContent: "flex-start",
                  }}
                >
                  <button
                    className="filterbutton"
                    id="all"
                    href="#projects"
                    active={(filter === "all").toString()}
                    onClick={() => {
                      setFilter("all");
                    }}
                  >
                    Alle
                  </button>
                  <button
                    className="filterbutton"
                    id="Reihenhäuser"
                    href="#projects"
                    active={(filter === "Reihenhäuser").toString()}
                    onClick={() => setFilter("Reihenhäuser")}
                  >
                    Reihenhäuser
                  </button>
                  <button
                    className="filterbutton"
                    id="mehrfamilienhaus"
                    href="#projects"
                    active={(filter === "mehrfamilienhaus").toString()}
                    onClick={() => setFilter("mehrfamilienhaus")}
                  >
                    Mehrfamilienhaus
                  </button>
                  <button
                    className="filterbutton"
                    id="sonstiges"
                    href="#projects"
                    active={(filter === "sonstiges").toString()}
                    onClick={() => setFilter("sonstiges")}
                  >
                    Sonstiges
                  </button>
                </div>

                <div
                  className="container row"
                  style={{
                    display: "flex",
                    backgroundColor: "black",
                    justifyContent: "flex-start",
                    marginTop: "15px",
                    paddingTop: "15px",
                    alignItems: "flex-start",
                  }}
                >
                  {getProjects()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  function getProjects() {
    return portfolio.map((item, id) =>
      item.category.includes(filter) ? (
        <ProjectCard
          key={id}
          link={item.link}
          name={item.name}
          category={item.category}
        >
          <div className="masonry-item__image-wrapper">
            <p
              style={{
                position: "absolute",
                transform: "rotate(30deg)",
                zIndex: 999,
                marginTop: 70,
                fontSize: 17,
                color: "red",
                fontWeight: "bold",
                background: "black",
              }}
            >
              Bilder bald verfügbar
            </p>
            {projectImages[projectImages.length - 1]}
          </div>
        </ProjectCard>
      ) : null
    );
  }
}

export default Projects;
