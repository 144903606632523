import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer footer--lite" style={{ paddingBottom: 5 }}>
      <div className="footer__inner container">
        <div
          className="row"
          style={{ display: "flex", marginLeft: 3, marginRight: 3 }}
        >
          <div
            style={{
              display: "flex",
              flex: 3,
              margin: "5px",
              alignItems: "flex-start",
              justifyContent: "start",
            }}
          >
            <ul className="footer__menu">
              <li className="footer__menu-item">
                <a className="footer__menu-link animsition-link" href="#home">
                  Home
                </a>
              </li>
              <li className="footer__menu-item">
                <a className="footer__menu-link animsition-link" href="#about">
                  über uns
                </a>
              </li>
              <li className="footer__menu-item">
                <a
                  className="footer__menu-link animsition-link"
                  href="#projects"
                >
                  Projekte
                </a>
              </li>
              <li className="footer__menu-item">
                <a
                  className="footer__menu-link animsition-link"
                  href="#services"
                >
                  Leistungen
                </a>
              </li>
              <li className="footer__menu-item">
                <a
                  className="footer__menu-link animsition-link"
                  href="#contact"
                >
                  Kontakt
                </a>
              </li>
            </ul>
          </div>
          <div style={{ flex: 2, margin: "5px" }}>
            <ul className="footer__menu">
              <li className="footer__menu-item">
                <a
                  className="footer__menu-link animsition-link"
                  href="DataPrivacy"
                >
                  Datenschutzerklärung
                </a>
              </li>
              <li className="footer__menu-item">
                <a className="footer__menu-link animsition-link" href="Imprint">
                  Impressum
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p
          style={{
            color: "white",
            padding: 5,
            textAlign: "center",
            margin: 10,
            marginTop: 50,
          }}
        >
          © 2018 - {currentYear} Hoxhaj Bauunternehmen GmbH.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
