import React from "react";

function SvgsProject() {
  return (
    <div style={{ display: "none" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <symbol id="linkedin" className="bi bi-linkedin" viewBox="0 0 16 16">
          <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
        </symbol>
        <symbol
          id="youtube"
          fill="white"
          className="bi bi-youtube"
          viewBox="0 0 16 16"
        >
          <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
        </symbol>
        <symbol id="arrow-angle" viewBox="0 0 512.002 512.002">
          <path d="M388.425 241.951L151.609 5.79c-7.759-7.733-20.321-7.72-28.067.04-7.74 7.759-7.72 20.328.04 28.067l222.72 222.105-222.728 222.104c-7.759 7.74-7.779 20.301-.04 28.061a19.8 19.8 0 0014.057 5.835 19.79 19.79 0 0014.017-5.795l236.817-236.155c3.737-3.718 5.834-8.778 5.834-14.05s-2.103-10.326-5.834-14.051z" />
        </symbol>
        <symbol id="chevron-down" viewBox="0 0 64 64">
          <path d="M32 48.1c-1.3 0-2.4-.5-3.5-1.3L.8 20.7C-.3 19.6-.3 18 .8 17c1.1-1.1 2.7-1.1 3.7 0L32 42.8l27.5-26.1c1.1-1.1 2.7-1.1 3.7 0 1.1 1.1 1.1 2.7 0 3.7L35.5 46.5c-1.1 1.4-2.2 1.6-3.5 1.6z" />
        </symbol>
        <symbol id="arrow-long" viewBox="0 0 64 64">
          <path d="M62.6 28.3l-7.5-8.8c-1.1-1.1-2.7-1.3-3.7-.3-1.1 1.1-1.3 2.7-.3 3.7l5.3 6.4H2.7C1.1 29.3 0 30.4 0 32s1.1 2.7 2.7 2.7h53.8l-5.3 6.4c-1.1 1.1-.8 2.9.3 3.7.5.5 1.1.5 1.6.5.8 0 1.6-.3 2.1-1.1l7.5-8.8c1.8-1.7 1.8-4.9-.1-7.1z" />
        </symbol>
        <symbol id="bricks" viewBox="0 0 64 64">
          <path d="M58.7 29.3h-5.3v-8c0-2.9-2.4-5.3-5.3-5.3H16c-2.9 0-5.3 2.4-5.3 5.3v8H5.3c-2.9 0-5.3 2.4-5.3 5.3v8c0 3 2.4 5.4 5.3 5.4h53.3c2.9 0 5.3-2.4 5.3-5.3v-8c.1-3-2.3-5.4-5.2-5.4zm-10.7 0H34.7v-8H48v8zm-8 5.4v8H24v-8h16zM16 21.3h13.3v8H16v-8zM5.3 34.7h13.3v8H5.3v-8zm40 8v-8h13.3v8H45.3z" />
        </symbol>
        <symbol id="behance" viewBox="0 0 24 24">
          <path d="M12.363 14.947c0-1.848-.879-3.214-2.695-3.726 1.325-.631 2.016-1.587 2.016-3.074 0-2.932-2.192-3.647-4.721-3.647H0v14.721h7.158c2.684 0 5.205-1.283 5.205-4.274zM3.246 7.013h3.046c1.171 0 2.225.328 2.225 1.682 0 1.25-.82 1.753-1.98 1.753H3.246zm-.001 9.708v-4.054h3.538c1.429 0 2.333.594 2.333 2.102 0 1.487-1.079 1.952-2.4 1.952zM18.796 19.5c2.554 0 4.208-1.147 5.004-3.585h-2.592c-.279.91-1.429 1.391-2.321 1.391-1.721 0-2.625-1.005-2.625-2.713h7.713c.244-3.418-1.66-6.331-5.18-6.331-3.259 0-5.471 2.442-5.471 5.641 0 3.32 2.096 5.597 5.472 5.597zm-.092-9.026c1.475 0 2.217.864 2.341 2.277h-4.779c.097-1.401 1.03-2.277 2.438-2.277zM15.667 5.273h5.988v1.45h-5.988z" />
        </symbol>
        <symbol id="chevron-right" viewBox="0 0 64 64">
          <path d="M46.8 28.5L20.6.8c-1.1-1.1-2.7-1.1-3.7 0-1.1 1.1-1.1 2.7 0 3.7L42.8 32 16.6 59.5c-1.1 1.1-1.1 2.7 0 3.7.5.5 1.1.8 1.9.8s1.3-.3 1.9-.8l26.1-27.7c2.1-1.9 2.1-5.1.3-7z" />
        </symbol>
        <symbol id="chevron-left" viewBox="0 0 64 64">
          <path d="M21.1 32L47.2 4.5c1.1-1.1 1.1-2.7 0-3.7s-2.7-1.1-3.7 0L17.4 28.5c-1.9 1.9-1.9 5.1 0 6.9l26.1 27.7c.5.5 1.3.8 1.9.8.5 0 1.3-.3 1.9-.8 1.1-1.1 1.1-2.7 0-3.7L21.1 32z" />
        </symbol>
        <symbol id="close" viewBox="0 0 64 64">
          <path d="M35.4 32l19.9-19.9c1-1 1-2.4 0-3.4s-2.4-1-3.4 0L32 28.6 12 8.8c-.9-1-2.4-1-3.3 0s-1 2.4 0 3.4L28.6 32 8.7 51.9c-1 1-1 2.4 0 3.4.5.4 1 .7 1.7.7s1.2-.2 1.7-.7l20-19.9 20 19.8c.5.4 1.2.7 1.7.7s1.2-.2 1.7-.7c1-1 1-2.4 0-3.4L35.4 32z" />
        </symbol>
        <symbol id="comments-reply" viewBox="0 0 64 64">
          <path d="M64 45.3V8c0-1.6-1.1-2.7-2.7-2.7H2.7C1.1 5.3 0 6.4 0 8v48c0 1.1.5 1.9 1.3 2.4.5.3.8.3 1.3.3s.8 0 1.3-.3L22.1 48h7.2l12.3 9.9c.8.5 1.6.8 2.7.8.5 0 1.1 0 1.9-.5 1.3-.8 2.4-2.1 2.4-3.7v-1.6c3.5.8 6.7 2.7 9.1 4 1.3.8 2.9.8 4.3 0 1.3-.8 2.1-2.1 2.1-3.7 0-.8 0-1.6-.3-2.4-.3-1.1-.5-2.1-1.1-3.2.5-.1 1.3-1.2 1.3-2.3zm-42.7-2.6c-.5 0-.8 0-1.3.3L5.3 51.5V10.7h53.3v32c-2.9-2.7-6.4-4.5-10.7-5.3v-2.9c0-1.6-.8-2.9-2.4-3.7-1.3-.8-3.2-.5-4.3.5l-12.7 10c-.5.3-.8.8-1.1 1.3h-6.1zm35 5.3c.8 1.1 1.1 1.9 1.6 3.2-3.5-1.9-8-3.7-12.3-4l-2.9-.3V52l-9.3-7.5 9.3-7.5v5.1l2.4.3c4 .5 7.5 2.1 10.1 4.8l.8.8h.3z" />
          <circle cx="16" cy="24" r="5.3" />
          <circle cx="32" cy="24" r="5.3" />
          <circle cx="48" cy="24" r="5.3" />
        </symbol>
        <symbol id="customer" viewBox="0 0 64 64">
          <path d="M61.1 47.1c-1.4-4.6-5.7-7.1-10.1-5.7-3 1.1-7.1 2.2-10.3 3.3 0-.8-.3-1.6-.5-2.5-1.1-2.7-4.1-4.1-6.5-4.1h-9.8c-.5 0-1.4-.3-2.2-1.1-1.1-1.1-2.7-1.6-4.4-1.6H9.6c-4.1.3-7.1 3.5-7.1 7.6v12.8c0 3.8 3.3 7.1 7.1 7.1h9.5c1.1 0 2.5-.3 3.5-1.1 2.7 1.6 6 2.2 9.3 2.2 1.9 0 4.1-.3 5.7-.8l18-5.4c5.7-2 6.6-7.1 5.5-10.7zM9.6 57.5c-.8 0-1.6-.8-1.6-1.9V42.8c0-.8.8-1.6 1.6-1.6h7.9c.3 0 .5 0 .5.3.3.3.5.3.8.5v15.5H9.6zM54 52.8l-18 5.4c-3.5 1.1-7.6.8-11.2-1.1l-.5-.5V43.8h9.5c.5 0 1.4.3 1.4.5.3.3-.3 1.1-.5 1.4-.8 1.1-1.9 2.2-2.2 3.5 0 1.4 1.1 2.5 2.5 2.5 2.5-.3 5.2-1.4 7.6-1.9 3.3-1.1 6.5-2.2 9.8-3.3 1.4-.5 3 .5 3.3 2.2.7 2-.1 3.3-1.7 4.1zm-3.3-36.5c4.4 0 8.2-3.5 8.2-8.2 0-4.4-3.5-8.2-8.2-8.2s-8.2 3.5-8.2 8.2 3.9 8.2 8.2 8.2zm0-10.6c1.4 0 2.7 1.1 2.7 2.7s-1.1 2.7-2.7 2.7-2.7-1-2.7-2.7 1.4-2.7 2.7-2.7zm-2.4 32.7h3.3c4.4 0 7.9-3.5 7.9-7.9v-5.7c0-4.4-3.5-7.9-7.9-7.9h-3c-4.4 0-7.9 3.5-7.9 7.9v5.4c-.1 4.7 3.5 8.2 7.6 8.2zm-2.2-13.9c0-1.4 1.1-2.5 2.5-2.5h3c1.4 0 2.5 1.1 2.5 2.5v5.7c0 1.4-1.1 2.5-2.5 2.5h-3.3c-1.1 0-2.2-1.1-2.2-2.7v-5.5z" />
        </symbol>
        <symbol id="facebook-outline" viewBox="0 0 64 64">
          <path d="M37.2 64H28c-2.5 0-4.5-2-4.5-4.5V37H17c-2.5 0-4.5-2-4.5-4.5v-6.9c0-2.5 2-4.6 4.5-4.6h6.5v-5.5c0-9.1 6.2-15.5 15-15.5H47c1.4 0 2.5 1.1 2.5 2.5v13c0 1.4-1.1 2.5-2.5 2.5h-5.5v3H47c1.4.1 2.7.7 3.5 1.8.8 1.1 1.2 2.4.9 3.8l-1.3 6.7c-.3 2.1-2.1 3.7-4.4 3.7h-4.3v22.5c.1 2.5-1.9 4.5-4.2 4.5zm-8.7-5h8V34.5c0-1.4 1.1-2.5 2.5-2.5h6.3l1.2-6H39c-1.4 0-2.5-1.1-2.5-2.5v-8c0-1.4 1.1-2.5 2.5-2.5h5.5V5h-6c-6.1 0-10 4.1-10 10.5v8c0 1.4-1.1 2.5-2.5 2.5h-8.5v6H26c1.4 0 2.5 1.1 2.5 2.5V59z" />
        </symbol>
        <symbol id="envelope-alt" viewBox="0 0 64 64">
          <path d="M58.7 10.7H5.3C2.4 10.7 0 13.1 0 16v32c0 2.9 2.4 5.3 5.3 5.3h53.3c2.9 0 5.3-2.4 5.3-5.3V16c.1-2.9-2.3-5.3-5.2-5.3zm0 35.7L40.5 29.3l18.1-10.9v28zM28.3 32.3c1.1.8 2.4 1.1 3.7 1.1 1.3 0 2.7-.3 3.7-1.1L52.5 48H10.7l17.1-16 .5.3zm24-16.3L33.1 27.7c-.5.3-1.3.3-1.9 0L11.7 16h40.6zM23.2 29.1L5.3 45.9V18.4l17.9 10.7z" />
        </symbol>
        <symbol id="facebook" viewBox="0 0 64 64">
          <path d="M47.9 25.6H40V15h6.5c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2h-7.6C30.7 0 25 5.9 25 14.4v11.2h-9c-1.5 0-2.7 1.2-2.7 2.8v7.4c0 1.5 1.2 2.7 2.7 2.7h9v22.9c0 1.5 1.2 2.7 2.7 2.7h9.8c.6 0 1.2-.3 1.6-.7.5-.5.7-1.2.7-1.8V38.4h6.8c1.3 0 2.4-.9 2.6-2.1v-.2l1.4-7.1c.2-.8 0-1.6-.6-2.4-.4-.5-1.2-.9-2.1-1z" />
        </symbol>
        <symbol id="dribbble" viewBox="0 0 64 64">
          <path d="M32 0C14.4 0 0 14.4 0 32s14.4 32 32 32 32-14.4 32-32S49.6 0 32 0zm27 32.8c-6.2-.5-12.7 0-19.1 1.5-.9-2.4-1.8-4.7-2.8-6.9 6.6-2.2 12.7-5.4 18-9.3C57.6 22.2 59 26.9 59 32v.8zm-6.8-18.7c-5 3.7-10.9 6.7-17.2 8.8-3.3-6.6-7.1-12.3-11-16.6 2.5-.9 5.2-1.3 8-1.3 8 0 15.2 3.5 20.2 9.1zM19 8.3c3.9 3.9 7.7 9.4 11.1 15.9-8.2 2-16.7 2.3-24.2.9C7.8 17.9 12.6 11.9 19 8.3zM5.1 30.1c3.4.6 7 1 10.6 1 5.4 0 11-.7 16.6-2.2 1 2.2 1.9 4.4 2.8 6.7C25.8 38.4 17.6 43 11 48.9 7.2 44.3 5 38.4 5 32c0-.6 0-1.3.1-1.9zm9.4 22.4c6.1-5.4 13.7-9.6 22.2-12.2.3 1 .7 2.1 1 3.2 1.4 4.8 2.4 9.5 3.2 14-2.8 1-5.7 1.5-8.8 1.5-6.8 0-12.9-2.5-17.6-6.5zm31 2.8c-.7-4.3-1.8-8.7-3.1-13.2l-.9-3c5.7-1.2 11.4-1.7 16.8-1.3-1.6 7.5-6.3 13.8-12.8 17.5z" />
        </symbol>
        <symbol id="github-original" viewBox="0 0 64 64">
          <path d="M32 .8C14.4.8 0 15.1 0 32.8c0 14.1 9.2 26.1 21.9 30.4 1.6.3 2.2-.7 2.2-1.5 0-.7 0-2.8-.1-5.5-8.9 2-10.8-4.3-10.8-4.3-1.5-3.7-3.6-4.6-3.6-4.6-2.9-2 .2-2 .2-2 3.2.2 4.9 3.3 4.9 3.3 2.8 4.9 7.5 3.5 9.3 2.6.3-2.1 1.1-3.5 2-4.3-7-.7-14.5-3.5-14.5-15.7 0-3.5 1.3-6.3 3.3-8.5-.3-.8-1.4-4 .3-8.5 0 0 2.7-.9 8.8 3.3 2.6-.7 5.3-1.1 8-1.1s5.5.3 8 1.1c6.1-4.1 8.7-3.3 8.7-3.3 1.7 4.4.7 7.7.3 8.5 2 2.2 3.3 5.1 3.3 8.5 0 12.2-7.5 15-14.6 15.7 1.1 1 2.2 3 2.2 5.9 0 4.3-.1 7.7-.1 8.7 0 .9.6 1.8 2.2 1.5C54.8 58.8 64 46.8 64 32.7 63.9 15.1 49.6.8 32 .8z" />
        </symbol>
        <symbol id="google-plus" viewBox="0 -91 512 512">
          <path d="M166 60c34.668 0 66.027 17.79 84 42.3l42.598-42.902C267.12 26.27 218.086 0 166 0 74.8 0 0 73.8 0 165s74.8 165 166 165c75.602 0 139.2-50.7 158.7-120 4.198-14.402 6.3-29.402 6.3-45v-15H181v59.988h79.5C244 245.391 207.7 270 166 270c-57.898 0-106-47.102-106-105S108.102 60 166 60zm0 0M466 90h-60v45h-45v60h45v45h60v-45h46v-60h-46zm0 0" />
        </symbol>
        <symbol id="heart-filled" viewBox="0 0 64 64">
          <path d="M30.6 59c.8.8 2.1.8 2.8 0l25.8-26.1c3.2-3.2 4.8-7.5 4.8-11.7s-1.6-8.8-5.1-12c-6.4-6.4-17.3-6.4-23.7 0L32 12.7l-3.5-3.5c-3.4-3.2-7.7-4.8-12-4.8S8 6 4.8 9.2c-6.4 6.4-6.4 17.3 0 23.7L30.6 59z" />
        </symbol>
        <symbol id="map-marker" viewBox="0 0 64 64">
          <path d="M32 0C18.7 0 8 11.2 8 25.3c0 12 16.5 31.7 21.6 37.6.5.8 1.6 1.1 2.4 1.1 1.1 0 1.9-.5 2.4-1.1C39.5 57.1 56 37.1 56 25.3 56 11.2 45.3 0 32 0zm0 57.6c-9.1-10.7-18.7-24.8-18.7-32.3 0-11.2 8.3-20 18.7-20s18.7 9.1 18.7 20c0 7.5-9.6 21.6-18.7 32.3z" />
          <path d="M32 13.3c-5.9 0-10.7 4.8-10.7 10.7S26.1 34.7 32 34.7 42.7 29.9 42.7 24 37.9 13.3 32 13.3zm0 16c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3 5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z" />
        </symbol>
        <symbol id="instagram" viewBox="0 0 64 64">
          <path d="M32 16.9c-8.3 0-15 6.8-15 15.1s6.7 15.1 15 15.1S47 40.3 47 32s-6.7-15.1-15-15.1zm0 25.2c-5.5 0-10-4.5-10-10.1s4.5-10.1 10-10.1S42 26.4 42 32s-4.5 10.1-10 10.1zM47 10c-2.8 0-5 2.3-5 5s2.2 5 5 5 5-2.3 5-5-2.2-5-5-5z" />
          <path d="M48 0H16C7.2 0 0 7.2 0 16v31.7C0 56.7 7.3 64 16.3 64h31.5C56.7 64 64 56.7 64 47.7V16c0-8.8-7.2-16-16-16zm11 47.7C59 53.9 54 59 47.8 59H16.3C10.1 59 5 54 5 47.8V16C5 9.9 9.9 5 16 5h32c6.1 0 11 4.9 11 11v31.7z" />
        </symbol>
        <symbol id="logo" viewBox="0 0 600 600">
          <path d="M0 259h39.9v301h5.4c53.7 0 107.3-.1 161 .1 4.2 0 5.6-1.3 6.7-5.2 28.3-96.6 56.7-193.1 85.1-289.7.4-1.4.9-2.7 1.9-5.6 1 3.1 1.5 4.9 2.1 6.7 28.3 96.2 56.6 192.5 84.9 288.7.9 3 1.6 5 5.8 5 54.5-.2 109-.1 163.5-.1h3.3V40.2H40.1v158.7H0V0h600v600H359c-4.3-14.6-8.5-29.3-12.8-43.9-12.2-41.6-24.4-83.2-36.7-124.8-3-10.1-6-20.2-9.4-31.7-1 2.8-1.6 4.1-2 5.4-3.1 10.5-6.1 21-9.2 31.6-16 54.5-32 109-48 163.4H-.1C0 486.3 0 372.7 0 259z" />
        </symbol>
        <symbol id="grid" viewBox="0 0 270 270">
          <path d="M114 0H10C4.5 0 0 4.5 0 10v104c0 5.5 4.5 10 10 10h104c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10zm-10 104H20V20h84v84zM260 0H156c-5.5 0-10 4.5-10 10v104c0 5.5 4.5 10 10 10h104c5.5 0 10-4.5 10-10V10c0-5.5-4.5-10-10-10zm-10 104h-84V20h84v84zm-136 42H10c-5.5 0-10 4.5-10 10v104c0 5.5 4.5 10 10 10h104c5.5 0 10-4.5 10-10V156c0-5.5-4.5-10-10-10zm-10 104H20v-84h84v84zm156-104H156c-5.5 0-10 4.5-10 10v104c0 5.5 4.5 10 10 10h104c5.5 0 10-4.5 10-10V156c0-5.5-4.5-10-10-10zm-10 104h-84v-84h84v84z" />
        </symbol>
        <symbol id="package" viewBox="0 0 17 17">
          <path d="M8.517-.035L0 3.186v10.693l8.5 3.188 8.5-3.188V3.187L8.517-.035zm6.567 3.563l-2.586.97-6.557-2.489 2.575-.974 6.568 2.493zM8.5 5.997L1.919 3.529l2.609-.986 6.551 2.487-2.579.967zM1 4.253l7 2.625v8.932l-7-2.625V4.253zM9 15.81V6.878l7-2.625v8.932L9 15.81z" />
        </symbol>
        <symbol id="menu" viewBox="0 -53 384 384">
          <path d="M368 154.668H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zM368 32H16C7.168 32 0 24.832 0 16S7.168 0 16 0h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 245.332H16c-8.832 0-16-7.168-16-16s7.168-16 16-16h352c8.832 0 16 7.168 16 16s-7.168 16-16 16zm0 0" />
        </symbol>
        <symbol id="pinterest" viewBox="0 0 64 64">
          <path d="M.2 31.9c0 .5 0 1 .1 1.5v.5c0 .3.1.7.1 1.1 0 .1 0 .3.1.5.1.4.1.8.1 1.2 0 .1 0 .2.1.3.1.5.1.9.3 1.4 0 .1.1.3.1.4.1.3.1.7.2 1 .1.1.1.3.1.5.1.3.2.7.3 1 .1.1.1.3.1.4.1.5.3.9.5 1.3.1.1.1.3.1.4.1.3.3.6.4.9.1.1.1.3.2.5.1.3.3.5.4.9.1.1.1.3.2.5.2.4.4.8.6 1.1.1.1.1.2.2.3.2.3.3.6.5.9-.1-.1.1.1.1.3.1.3.3.5.5.7.1.1.2.3.3.5.2.3.4.5.6.8.1.1.1.2.2.3.3.3.5.7.8 1 .1.1.2.3.3.4.2.2.4.4.5.7l.4.4c.2.2.4.5.6.7.1.1.2.3.3.3l.9.9c.1.1.3.2.3.3.2.2.5.4.7.6.1.1.3.3.4.3.2.2.5.4.7.5.1.1.3.2.4.3.3.3.7.5 1.1.8.1.1.2.1.3.2.3.2.6.4.9.5.1.1.3.2.5.3.3.1.5.3.8.5.1.1.3.1.4.3.4.2.7.4 1.1.6.1 0 .1.1.1.1.4.2.9.4 1.3.6.1.1.3.1.4.1.2.1.3.1.5.2-.3-2.5-.5-6.4.1-9.2.6-2.5 3.7-15.9 3.7-15.9s-.9-1.9-.9-4.7c0-4.5 2.6-7.8 5.8-7.8 2.7 0 4.1 2.1 4.1 4.5 0 2.7-1.7 6.8-2.7 10.6-.7 3.2 1.6 5.8 4.7 5.8 5.6 0 10-6 10-14.6 0-7.6-5.5-13-13.3-13-9.1 0-14.4 6.8-14.4 13.8 0 2.7 1.1 5.6 2.4 7.3.3.3.3.6.2.9-.3 1-.8 3.2-.9 3.6-.1.6-.5.7-1.1.4-4-1.9-6.4-7.7-6.4-12.4 0-10 7.3-19.3 21.1-19.3 11.1 0 19.7 7.9 19.7 18.4 0 11-6.9 19.9-16.5 19.9-3.3 0-6.2-1.7-7.3-3.7 0 0-1.6 6.1-2 7.6-.7 2.8-2.7 6.2-4 8.4.4.1.9.3 1.3.4.1.1.3.1.5.1.3.1.7.1.9.2.1.1.3.1.5.1.4.1.9.1 1.3.2h.1c.5.1.9.1 1.4.2.1 0 .3 0 .5.1.3.1.7.1 1.1.1h.4c.5 0 1 .1 1.5.1C49.7 63.8 64 49.5 64 31.9c0-13.6-8.5-25.2-20.4-29.8-.3-.3-.7-.5-1.1-.7-.9-.3-1.9-.6-2.9-.8-.9-.2-1.7-.4-2.5-.5C36 0 35-.2 33.9-.2h-1.7c-1.1 0-2.2.1-3.3.1-.5.1-1.1.1-1.6.2C11.9 2.7.2 16 .2 31.9z" />
        </symbol>
        <symbol id="phone" viewBox="0 0 64 64">
          <path d="M61.6 44.1l-9.3-5.9c-2.4-1.3-5.3-.8-6.9 1.3l-2.1 3.2c-12-7.2-19.5-16.8-22.4-21.1l3.5-2.1c1.1-.8 1.9-1.9 2.1-3.2.3-1.3 0-2.7-.8-3.7l-6.1-8.8C18 1.7 15.1.9 12.9 2.2L4 7c-.5.3-.8.5-1.3.8C-4 15 2.1 31.3 17.6 45.9c11.2 10.7 23.2 16.5 31.7 16.5 3.2 0 5.9-.8 7.7-2.4l1.1-1.1 5.1-8c1.6-2.3.8-5.5-1.6-6.8zm-8 11.9c-4.6 3.8-18.9-1-32.5-13.8C7.7 29.4 2.7 15.8 6.7 11.5L15 6.7l5.9 8.5-3.5 2.1c-1.1.8-1.9 1.9-2.1 3.2-.3 1.3 0 2.7.8 3.7 2.9 4.3 11.2 15.2 24.3 23.2 2.4 1.3 5.3.8 6.9-1.3l2.4-3.5 9.1 5.6-5.2 7.8z" />
        </symbol>
        <symbol id="ruler-pencil" viewBox="0 0 17 17">
          <path d="M4.492 0H2.508c-.827 0-1.5.673-1.5 1.5v10.657l2.521 4.956 2.464-4.959V1.5A1.503 1.503 0 004.492 0zM2.508 1h1.984c.275 0 .5.225.5.5v1.019H2.008V1.5a.5.5 0 01.5-.5zm-.5 3.55V3.519h2.984V4.55H2.008zm1.94 9.471h-.87l-1.07-2.104V5.55h1v6.481h1V5.55h.984v6.37l-1.044 2.101zM8 0v17h7V0H8zm6 16H9v-.984h2v-1H9V13h1v-1H9v-1h2v-1H9v-.984h1v-1H9v-1h2v-1H9V5h1V4H9V3h2V2H9V1h5v15z" />
        </symbol>
        <symbol id="play" viewBox="0 0 64 64">
          <path d="M56.6 27.9L12.8.7C11.2-.1 9.3-.4 8 .7c-1.6.5-2.7 2.1-2.7 4v54.7c0 1.6 1.1 3.2 2.4 4 .8.3 1.6.5 2.4.5s1.9-.3 2.7-.8L56.6 36c1.3-.8 2.1-2.4 2.1-4s-.8-3.3-2.1-4.1zm-46 30.2V5.8L52.8 32 10.6 58.1z" />
        </symbol>
        <symbol id="rss" viewBox="0 0 448 448">
          <circle cx="64" cy="384" r="64" />
          <path d="M0 149.344v85.344c117.632 0 213.344 95.68 213.344 213.312h85.312c0-164.672-133.984-298.656-298.656-298.656z" />
          <path d="M0 0v85.344C200 85.344 362.688 248 362.688 448H448C448 200.96 247.04 0 0 0z" />
        </symbol>
        <symbol id="search" viewBox="0 0 64 64">
          <path d="M62.9 56.5l-17-13.8c7.2-9.9 6.1-23.7-2.7-32.5C38.4 5.3 32 2.7 25.3 2.7s-13 2.6-17.8 7.4S0 21.3 0 28s2.7 13.1 7.5 17.9c5.1 5.1 11.5 7.5 17.9 7.5 6.1 0 12.3-2.1 17.1-6.7l17.3 14.1c.5.5 1.1.5 1.6.5.8 0 1.6-.3 2.1-1.1.8-1 .8-2.6-.6-3.7zM25.3 48c-5.3 0-10.4-2.1-14.1-5.9-3.7-3.7-5.9-8.8-5.9-14.1s2.1-10.4 5.9-14.1S20 8 25.3 8s10.4 2.1 14.1 5.9 5.9 8.8 5.9 14.1-2.1 10.4-5.9 14.1c-3.7 3.8-8.7 5.9-14.1 5.9z" />
        </symbol>
        <symbol id="ticket-alt" viewBox="0 0 64 64">
          <path d="M48.7 0H16.2c-4.5 0-8 3.5-8 8v52.5c0 1.1.8 2.1 1.9 2.7 1.1.3 2.4 0 2.9-1.1l4.5-6.7 5.1 7.5c.5.8 1.3 1.1 2.1 1.1s1.6-.5 2.1-1.1l5.1-7.5 5.1 7.5c.5.8 1.3 1.1 2.1 1.1s1.6-.5 2.1-1.1l5.1-7.5 4.5 6.9c.5 1.1 1.9 1.3 2.9 1.1 1.1-.3 1.9-1.3 1.9-2.7V8c1.1-4.5-2.4-8-6.9-8zm2.1 51.7L49 48.8c-.5-.8-1.3-1.1-2.1-1.1s-1.6.5-2.1 1.1l-5.1 7.5-5.1-7.5c-.8-.5-1.6-1.1-2.4-1.1s-1.6.5-2.1 1.1L25 56.3l-5.1-7.5c-.5-.8-1.3-1.1-2.1-1.1s-1.6.5-2.1 1.1l-2.1 2.9V8c0-1.6 1.1-2.7 2.7-2.7h32C49.9 5.3 51 6.4 51 8v43.7z" />
          <path d="M42.8 18.7H21.5c-1.6 0-2.7 1.1-2.7 2.7s1.1 2.7 2.7 2.7h21.3c1.6 0 2.7-1.1 2.7-2.7 0-1.7-1.1-2.7-2.7-2.7zm0 10.6H21.5c-1.6 0-2.7 1.1-2.7 2.7s1.1 2.7 2.7 2.7h21.3c1.6 0 2.7-1.1 2.7-2.7s-1.1-2.7-2.7-2.7z" />
        </symbol>
        <symbol id="timer" viewBox="0 0 64 64">
          <path d="M34.7 10.9V5.3H40c1.6 0 2.7-1.1 2.7-2.7S41.6 0 40 0H24c-1.6 0-2.7 1.1-2.7 2.7s1.1 2.7 2.7 2.7h5.3V11c-13.3 1.3-24 12.8-24 26.4C5.3 52 17.3 64 32 64s26.7-12 26.7-26.7c0-13.8-10.7-25-24-26.4zM32 58.7c-11.7 0-21.3-9.6-21.3-21.3S20.3 16 32 16s21.3 9.6 21.3 21.3S43.7 58.7 32 58.7z" />
          <path d="M45.3 34.7H34.7V24c0-1.6-1.1-2.7-2.7-2.7s-2.7 1.1-2.7 2.7v10.7h-2.7c-1.6 0-2.7 1.1-2.7 2.7s1.1 2.7 2.7 2.7h2.7v2.7c0 1.6 1.1 2.7 2.7 2.7s2.7-1.1 2.7-2.7V40h10.7c1.6 0 2.7-1.1 2.7-2.7-.1-1.6-1.2-2.6-2.8-2.6z" />
        </symbol>
        <symbol id="tumblr">
          <path d="M9.104.5v4.042h3.793v2.51H9.104v4.104c0 .927.042 1.521.146 1.792.094.261.281.468.541.625.354.218.761.323 1.219.323.813 0 1.615-.261 2.417-.793v2.521c-.688.323-1.302.552-1.854.678a7.612 7.612 0 01-1.803.197c-.729 0-1.374-.094-1.938-.281a4.149 4.149 0 01-1.437-.781c-.395-.343-.676-.708-.822-1.093-.156-.386-.229-.947-.229-1.677V7.052H3.573V4.791a4.907 4.907 0 001.614-.874 4.464 4.464 0 001.074-1.375C6.531 2 6.719 1.323 6.823.5h2.281z" />
        </symbol>
        <symbol id="twitter" viewBox="0 0 64 64">
          <path d="M20.1 58.1c24.2 0 37.4-20 37.4-37.4 0-.5 0-1.1-.1-1.7 2.6-1.9 4.8-4.2 6.5-6.8-2.4 1.1-4.9 1.8-7.6 2.1 2.7-1.6 4.8-4.2 5.8-7.3-2.6 1.5-5.3 2.6-8.4 3.2C51.3 7.6 47.9 6 44.1 6c-7.3 0-13.2 5.9-13.2 13.2 0 1 .1 2 .3 3-10.6-.6-20.3-5.8-26.8-13.8-1.1 2-1.8 4.2-1.8 6.6 0 4.6 2.3 8.6 5.9 10.9-2.2-.1-4.2-.7-5.9-1.6v.1c0 6.3 4.5 11.7 10.5 12.9-1.1.3-2.3.5-3.4.5-.9 0-1.7-.1-2.5-.3 1.7 5.2 6.5 9 12.3 9.1-4.5 3.5-10.2 5.6-16.3 5.6-1.2.1-2.2 0-3.2-.1 5.7 3.8 12.7 6 20.1 6" />
        </symbol>
        <symbol id="twitter-outline" viewBox="0 0 64 64">
          <path d="M22.2 58c-5.4 0-13.3-1-20-5.8-2-1.4-2.8-4.1-1.8-6.4.9-2.3 3.4-3.6 5.9-3.1 0 0 1 .2 2.5.1-1-1.4-1.9-3-2.5-4.7-.2-.6-.3-1.1-.3-1.7-2.5-2.9-3.8-6.5-3.8-10.5 0-.9.2-1.8.7-2.5C2 20 1.7 15.5 4.6 10.7 5.5 9.3 7 8.3 8.7 8.1h.2c1.8-.1 3.4.6 4.5 1.8.7.7 6.1 6.4 13 8.3C28 11.2 34.4 6 42.1 6c3.4 0 6.7 1 9.3 2.9.7-.2 1.6-.5 2.4-1.1 1.6-1.1 3.7-1.3 5.4-.4 1.7.8 2.9 2.4 3.1 4.3v.9c1.4 1.4 1.9 3.4 1.3 5.2-.5 1.7-2.1 3.8-5.4 6.8-.2 7.4-3.7 27.2-28.6 32.8-.2 0-3.2.6-7.4.6zM5.4 47.6c-.2 0-.3.1-.3.1v.1c-.1.1-.1.2 0 .3 5.6 4.1 12.4 4.9 17.1 4.9 3.7 0 6.3-.5 6.4-.5 22.6-5.1 24.8-23.6 24.9-29.2 0-.7.3-1.4.8-1.8 4.2-3.7 4.7-5 4.7-5.1.1-.2-.1-.4-.2-.4l-.1-.1c-1.1-.5-1.7-1.8-1.3-3 .1-.3.1-.5.1-.7 0-.1-.1-.2-.2-.3-.1 0-.1-.1-.2-.1s-.2 0-.4.1c-2.2 1.5-4.5 2-5.6 2.1-.7.1-1.3-.1-1.8-.5-1.9-1.6-4.5-2.4-7.1-2.4-6.1 0-11 4.5-11.2 10.4 0 .7-.3 1.4-.9 1.8-.5.5-1.2.7-1.9.6-9.5-1.3-17-8.9-18.5-10.4l-.1-.1c-.1-.1-.3-.1-.4-.1-.2 0-.3.2-.4.3-1.8 3-2 6.2-.7 9.4.4 1 .2 2.2-.7 2.9l-.2.2c0 3.2 1 5.8 3.2 7.8.7.7.9 1.6.7 2.5v.3c.8 2.5 2.4 4.5 4.6 5.8.8.5 1.3 1.4 1.2 2.4-.1 1-.8 1.8-1.7 2.1-4.6 1.5-9.5.8-9.7.7 0-.1-.1-.1-.1-.1zm53.1-31.9z" />
          <path
            d="M-5.7.9zM32 32z"
            fill="none"
            stroke="#000"
            strokeMiterlimit="10"
          />
        </symbol>
        <symbol id="users" viewBox="0 0 64 64">
          <path d="M21.3 37.3c7.5 0 13.3-5.9 13.3-13.3s-5.9-13.3-13.3-13.3S8 16.5 8 24s5.9 13.3 13.3 13.3zm0-21.3c4.5 0 8 3.5 8 8s-3.5 8-8 8-8-3.5-8-8 3.5-8 8-8zM21.3 40C13.6 40 6.4 43.2.8 48.8c-1.1 1.1-1.1 2.7 0 3.7s2.7 1.1 3.7 0C9 48 15.2 45.3 21.3 45.3s12 2.7 16.8 7.2c.5.5 1.3.8 1.9.8.8 0 1.3-.3 1.9-.8 1.1-1.1 1.1-2.7 0-3.7-5.7-5.6-13.1-8.8-20.6-8.8zM48 37.3c4.5 0 8-3.5 8-8s-3.5-8-8-8-8 3.5-8 8 3.4 8 8 8zm0-10.6c1.6 0 2.7 1.1 2.7 2.7s-1.1 2.7-2.7 2.7-2.7-1.1-2.7-2.7c0-1.7 1.1-2.7 2.7-2.7zM63.2 46.1c-5.6-5.3-13.3-7.2-21.1-5.3-1.3.3-2.4 1.9-1.9 3.2.3 1.3 1.9 2.4 3.2 1.9 5.9-1.6 12 0 16.3 4 .5.5 1.1.8 1.9.8.8 0 1.3-.3 1.9-.8.7-1.1.7-3-.3-3.8z" />
        </symbol>
        <symbol id="wallet" viewBox="0 0 64 64">
          <path d="M56.5 18.2h-3.6c.7-1.3 1.1-2.9 1.1-4.5 0-5.2-4.3-9.5-9.5-9.5h-35C4.3 4.2 0 8.5 0 13.7v38.6c0 4.1 3.4 7.5 7.5 7.5h49c4.1 0 7.5-3.4 7.5-7.5V25.7c0-4.1-3.4-7.5-7.5-7.5zM59 44.6H48.5c-.4 0-.7-.3-.7-.7v-9.8c0-.4.3-.7.7-.7H59v11.2zM9.5 9.2h35c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5h-35c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5zm47 45.6h-49c-1.4 0-2.5-1.1-2.5-2.5V22.1c1.3.7 2.9 1.1 4.5 1.1h47c1.4 0 2.5 1.1 2.5 2.5v2.7H48.5c-3.1 0-5.7 2.6-5.7 5.7v9.8c0 3.1 2.6 5.7 5.7 5.7H59v2.7c0 1.4-1.1 2.5-2.5 2.5z" />
          <circle cx="54" cy="39" r="2" />
        </symbol>
        <symbol id="vector" viewBox="0 0 17 17">
          <path d="M14.94 6a6.46 6.46 0 00-3.44-1 6.49 6.49 0 00-2.239.404l2.569-2.569c.203.102.428.165.67.165.827 0 1.5-.673 1.5-1.5S13.327 0 12.5 0 11 .673 11 1.5c0 .22.05.428.136.616l-3.91 3.909A1.46 1.46 0 006.976 6c-.827 0-1.5.673-1.5 1.5 0 .085.011.168.025.25l-3.386 3.386A1.472 1.472 0 001.5 11c-.827 0-1.5.673-1.5 1.5S.673 14 1.5 14 3 13.327 3 12.5c0-.242-.063-.467-.165-.669l2.57-2.57A6.516 6.516 0 005 11.5c0 1.469.612 2.735 1 3.391V17h3v-3H6.664C6.246 13.174 6 12.287 6 11.5c0-.887.22-1.764.628-2.545.113.027.228.045.348.045.827 0 1.5-.673 1.5-1.5 0-.18-.037-.35-.095-.51 1.644-1.131 3.847-1.268 5.619-.373V9h3V6h-2.06zM12.5 1a.5.5 0 110 1 .5.5 0 010-1zm-11 12a.5.5 0 110-1 .5.5 0 010 1zM7 15h1v1H7v-1zm-.024-7a.5.5 0 110-1 .5.5 0 010 1zM16 8h-1V7h1v1z" />
        </symbol>
      </svg>
    </div>
  );
}

export default SvgsProject;
