import React, { useState, useEffect } from "react";

import companyLogo from "../img/own/company-logo.png";
import Tilt from "react-parallax-tilt";

import "../styles/sidenavigation.css";

function SideNavigation() {
  const [active, setActive] = useState(0);


  useEffect(() => {
    let options = { threshold: 0.5 };

    let callback = (entries) => {
      entries.forEach((element) => {
        if (!element.isIntersecting) return;
        switch (element.target.id) {
          case "home":
            setActive(0);
            break;
          case "about":
            setActive(1);
            break;
          case "projects":
            setActive(2);
            break;
          case "services":
            setActive(3);
            break;
          case "contact":
            setActive(4);
            break;
          default: break;
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    const navItems = ["home", "about", "projects", "services", "contact"];

    let entries = navItems.map((x) => document.querySelector("#" + x));

    // add all items to observer -> when intersecting, set navigation link active
    entries.forEach((item) => observer.observe(item));
  }, [active]);

  return (
    <header className="header header--aside sideNavigation sideNavAnimated">
      <div className="header__inner">
        <span className="header__logo logo" >
          <Tilt>
            <span className="logo__image" style={{ width: "auto", margin: 0 }}>
              <img width="220" alt="Hoxhaj Bauunternehmen GmbH" height="240" src={companyLogo} />
            </span>
          </Tilt>
        </span>

        <nav
          className="header__nav navigation navigation--column"
          style={{ marginBottom: "0px" }}
        >
          <ul className="navigation__list">
            <li
              className={`navigation__item ${active === 0 ? "navigation__item--current" : ""
                }`}
            >
              <a className="navigation__link" href="#start">
                Home
              </a>
            </li>
            <li
              className={`navigation__item ${active === 1 ? "navigation__item--current" : ""
                }`}
            >
              <a className="navigation__link" href="#about">
                Über uns
              </a>
            </li>
            <li
              className={`navigation__item ${active === 2 ? "navigation__item--current" : ""
                }`}
            >
              <a className="navigation__link" href="#projects">
                Projekte
              </a>
            </li>
            <li
              className={`navigation__item ${active === 3 ? "navigation__item--current" : ""
                }`}
            >
              <a className="navigation__link" href="#services">
                Leistungen
              </a>
            </li>
            <li
              className={`navigation__item ${active === 4 ? "navigation__item--current" : ""
                }`}
            >
              <a className="navigation__link" href="#contact">
                Kontakt
              </a>
            </li>
          </ul>
        </nav>

        <hr />

        <div
          style={{
            color: "white",
            margin: "8px 0px 15px 0px",
            textTransform: "uppercase",
          }}
        >
          Folgen Sie uns
        </div>

        <ul className="header__social social">
          <li className="social__item">
            <a
              className="social__link"
              href="https://www.youtube.com/channel/UCIv3E3zRcPsrN2MpcwiOqyA"
              target="_blank"
              rel="noreferrer"
            >
              <svg width="18" height="18" aria-label="youtube icon">
                <use xlinkHref="#youtube"></use>
              </svg>
              <span className="visually-hidden">youtube</span>
            </a>
          </li>
          <li className="social__item">
            <a
              className="social__link"
              href="https://de-de.facebook.com/hoxhajgmbh/"
              target="_blank"
              rel="noreferrer"
            >
              <svg width="18" height="18" aria-label="facebook icon">
                <use xlinkHref="#facebook"></use>
              </svg>
              <span className="visually-hidden">facebook</span>
            </a>
          </li>
          <li className="social__item">
            <a
              className="social__link"
              href="https://www.instagram.com/hoxhajgmbh/"
              target="_blank"
              rel="noreferrer"
            >
              <svg width="18" height="18" aria-label="instagram icon">
                <use xlinkHref="#instagram"></use>
              </svg>
              <span className="visually-hidden">instagram</span>
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default SideNavigation;
