import React from "react";
import Colors from "./Colors";

function CompanyApproach() {
  return (
    <section id="services" className="about__approach approach">
      <div className="approach__inner container">
        <h2 className="approach__heading heading">Unser Vorgehen</h2>
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="approach__card">
              <div className="approach__card-icon">
                <svg width="48" height="48" fill={Colors.ICON_COLOR}>
                  <use xlinkHref="#ticket-alt"></use>
                </svg>
              </div>
              <h3 className="approach__card-heading">Kontaktaufnahme</h3>
              <div className="approach__card-text">
                Nehmen Sie gerne Kontakt mit uns auf. Wir beantworten gerne Ihre
                Fragen und finden eine Lösung zu Ihrem Anliegen.
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="approach__card">
              <div className="approach__card-icon">
                <svg width="48" height="48" fill={Colors.ICON_COLOR}>
                  <use xlinkHref="#bricks"></use>
                </svg>
              </div>
              <h3 className="approach__card-heading">Vertrag</h3>
              <div className="approach__card-text">
                Nachdem wir die Anforderungen erfasst haben, stellen wir Ihnen
                den Vertrag fertig.
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="approach__card">
              <div className="approach__card-icon">
                <svg width="48" height="48" fill={Colors.ICON_COLOR}>
                  <use xlinkHref="#customer"></use>
                </svg>
              </div>
              <h3 className="approach__card-heading">Auftrag</h3>
              <div className="approach__card-text">
                Wir führen zeitnah den im Vertrag festgelegten Auftrag durch.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CompanyApproach;
