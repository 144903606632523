import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";

import { motion, useViewportScroll } from "framer-motion";

import "../styles/parallaxoverflow.css";
import "../styles/parallaxTheme.css";

import frontimg from "../img/parallax/zoom-image-head-bg2.png";

function ParallaxOverflow() {
  const refImg = useRef(null);
  const bgImageRef = useRef(null);

  const { scrollYProgress } = useViewportScroll();

  const [y, setY] = useState(0);

  const zoom_speed = 0.1;
  const [ZOOM, setZoom] = useState(1);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;

      if (y > window.scrollY) {
        if (ZOOM !== 1.0) setZoom(ZOOM - zoom_speed);
        bgImageRef.current.style.backgroundSize = "100% 100%";
      } else if (y < window.scrollY) {
        if (ZOOM <= 1.5) setZoom(ZOOM + zoom_speed);
        bgImageRef.current.style.backgroundSize = "120% 120%";
      }

      refImg.current.style.transform = "scale( " + ZOOM + ")";

      setY(window.scrollY);
    },
    [y, ZOOM]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div
      id="doubleParallax"
      ref={bgImageRef}
      className="doubleParallax"
      style={{ color: "black" }}
    >
      <div className="zoom-image-head">
        <div className="row center topbar">
          <nav className="topNav">
            <a href="#home">Home</a>
            <a href="#about">Über uns</a>
            <a href="#projects">projekte</a>
            <a href="#services">leistungen</a>
            <a href="#contact">kontakt</a>
          </nav>
        </div>

        <div className="welcome-text-parallax" style={{ fontSize: "2.25em" }}>
          Herzlich Willkommen bei Hoxhaj Bauunternehmen Gmbh
        </div>

        <div className="zoom-image-head__body">
          <div className="zoom-image-head__bg">
            <div className="zoom-image-head__bg-inner"></div>
          </div>
          <img
            alt=""
            ref={refImg}
            className={"zoom-image-head__bg2"}
            src={frontimg}
          />

          <motion.path style={{ pathLength: scrollYProgress }} />
        </div>
      </div>
    </div>
  );
}

export default ParallaxOverflow;
