import React, { useState } from "react";
import Colors from "./Colors";
import { BsEnvelopeFill, BsMap } from "react-icons/bs";
import { AiOutlinePhone, AiOutlineClockCircle } from "react-icons/ai";

import { useMediaQuery } from "react-responsive";

const fontstyle = {
  fontSize: "22px",
  color: "white",
};

const iconColor = Colors.ICON_COLOR;

const Contact = (props) => {
  const isFormEnabled = props.isFormEnabled;
  const isMobile = useMediaQuery({ query: "(max-width: 764px)" });
  const marginText = isMobile ? 50 : 0;
  const paddingText = isMobile ? 30 : 0;

  const contactIconStyle = {
    display: isMobile ? "flex" : "block",
    justifyContent: isMobile ? "center" : "left",
    marginBottom: isMobile ? 10 : 0,
  };
  const contactTextStyle = { textAlign: isMobile ? "center" : "left" };
  const [formularAction, setFormularAction] = useState("#");

  return (
    <section className="about__contacts" id="contact">
      <div className="container">
        <h2 className="latest-projects__heading heading">Kontakt</h2>
        <div className="row">
          <div
            className="contactinfo col-12 col-lg-6"
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <div className="contact-block">
              <div className="row contact-entry">
                <div className="col-sm-2" style={contactIconStyle}>
                  <BsMap color={iconColor} size="40" className="contactIcon" />
                </div>

                <div className="col" style={contactTextStyle}>
                  <a
                    style={fontstyle}
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.google.de/maps/place/HOXHAJ+Bauunternehmen+GmbH/@49.1949049,8.3036619,18.75z/data=!4m5!3m4!1s0x4797ad0dec0d0479:0x1c679596cddfafa9!8m2!3d49.19495!4d8.30375"
                  >
                    Bellheim, <span>Rheinland-Pfalz</span>
                  </a>
                  <address className="contact-block__address">
                    Am Weidensatz 24
                  </address>
                </div>
              </div>

              <div className="row contact-entry">
                <div className="col-sm-2" style={contactIconStyle}>
                  <BsEnvelopeFill
                    color={iconColor}
                    size="40"
                    className="contactIcon"
                  />
                </div>

                <div className="col" style={contactTextStyle}>
                  <a style={fontstyle} href="mailto:infor@liarchstudio.co">
                    info@hoxhaj-gmbh.de
                  </a>
                  <address className="contact-block__address">E-Mail</address>
                </div>
              </div>

              <div className="row contact-entry">
                <div className="col-sm-2" style={contactIconStyle}>
                  <AiOutlinePhone
                    color={iconColor}
                    size="40"
                    className="contactIcon"
                  />
                </div>

                <div className="col" style={contactTextStyle}>
                  <a style={fontstyle} href="tel:+4972729559210">
                    +(49)7272 9559210
                  </a>
                  <address className="contact-block__address">Telefon</address>
                </div>
              </div>

              <div className="row contact-entry">
                <div className="col-sm-2" style={contactIconStyle}>
                  <AiOutlineClockCircle
                    color={iconColor}
                    size="40"
                    className="contactIcon"
                  />
                </div>

                <div className="col" style={contactTextStyle}>
                  <a href="#/" style={fontstyle}>
                    08:00 Uhr - 17:00 Uhr
                  </a>
                  <address className="contact-block__address">
                    Öffnungszeiten
                  </address>
                </div>
              </div>
            </div>
          </div>
          <div className="formular col-12 col-lg-6">
            <div
              className="contact-block__title"
              style={{
                fontSize: "1.4rem",
                marginBottom: marginText,
                marginTop: marginText,
                paddingLeft: paddingText,
                paddingRight: paddingText,
              }}
            >
              Wir helfen Ihnen&nbsp;Träume zu verwirklichen und mehr zu
              bringen&nbsp;als Sie erwarten.
            </div>
            <form
              className="about__feedback feedback-form js-form-validate"
              action={formularAction}
              method="post"
              encType="multipart/form-data"
            >
              <label
                className="feedback-form__field field field--bordered"
                aria-label="Your name"
              >
                <input
                  required
                  type="text"
                  name="name"
                  placeholder="Vor- und Nachname *"
                />
                <div className="field-error" style={{ display: "none" }}></div>
              </label>
              <label
                className="feedback-form__field field field--bordered"
                aria-label="Email Addresse"
              >
                <input
                  type="email"
                  name="email"
                  placeholder="Email Addresse *"
                />
                <div className="field-error" style={{ display: "none" }}></div>
              </label>
              <label
                className="feedback-form__field field field--bordered"
                aria-label="Subject"
              >
                <input
                  type="text"
                  name="subject"
                  placeholder="Betreff (optional)"
                />
              </label>
              <label
                className="feedback-form__field field field--bordered"
                aria-label="Ihre-nachricht"
              >
                <textarea
                  required
                  name="message"
                  placeholder="Ihre Nachricht *"
                ></textarea>
                <div className="field-error" style={{ display: "none" }}></div>
              </label>

              {!isFormEnabled && (
                <p>
                  Bitte akzeptieren Sie erst die Cookies, bevor sie fortfahren.
                </p>
              )}

              {isFormEnabled ? (
                <button
                  className="feedback-form__btn btn"
                  style={{
                    backgroundColor: Colors.BUTTON_COLOR,
                    border: "none",
                  }}
                  type="submit"
                  onClick={() => {
                    if (isFormEnabled)
                      setFormularAction(
                        "https://getform.io/f/3c1d1bd5-b275-43d8-877b-42f3ccd36cd2"
                      );
                  }}
                >
                  Anfrage senden
                </button>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
