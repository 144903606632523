import React from "react";
import Tilt from "react-parallax-tilt";

function AboutCard(props) {
  return (
    <Tilt tiltReverse={true}>
      <div className="aboutCard"
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          backgroundColor: "#5b5f64",
          flex: "2",
          width: "180px",
          height: "150px",
          padding: "15px",
          fontSize: "12px",
          justifyContent: "center",
          alignItems: "center",
          margin: "7px"
        }}
      >
        <div style={{ flex: "1" }}>
          <img src={props.img} width="50" alt={props.img.toString()} color="white" />
          <p className="aboutText" style={{ fontSize: "13.5px", textAlign: "center", }}>{props.text}</p>
        </div>
      </div>
    </Tilt>
  );
}

export default AboutCard;
